/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

html,
body {
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  word-break: break-word;
}

/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 {
  // margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 300; }
h1 {
  font-size: 5rem;
  letter-spacing: -.15rem;
  line-height: 1.2;
  margin-top: 1.8rem;
}
h2 {
  font-size: 4rem;
  letter-spacing: -.1rem;
  line-height: 1;
  margin-top: 1.4rem;
}
h3 {
  font-size: 2.8rem;
  letter-spacing: -.1rem;
  line-height: 1;
  margin-top: 1.2rem;
}
h4 {
  font-size: 2.8rem;
  letter-spacing: -.08rem;
  line-height: 1.1;
}
h5 {
  font-size: 2.4rem;
  letter-spacing: -.05rem;
  line-height: 1.1;
}
h6 {
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.1;
}

@media (max-width: 600px) {
  h1 {
    font-size: 4.6rem;
  }
  h2 {
    font-size: 3.6rem;
  }
  h3 {
    font-size: 2.2rem;
  }
  h4 {
    font-size: 2.2rem;
  }
  h5 {
    font-size: 1.8 2.4rem;
  }
  h6 {
    font-size: 1.5 1.5rem;
  }
}

p {
  margin-top: 0;
}

a {
  color: hsl(200deg 91% 35%);
  text-decoration: none;
  &:hover {
    color: hsl(200deg 91% 25%);
    text-decoration: underline;
  }
}

img {
  border-radius: 5px;
  height: auto;
  vertical-align: middle;
  width: 100%;
}
